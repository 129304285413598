<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Añade, edita y elimina inversores retail'">
      <template v-slot:actions>
        <v-btn v-show="permissionsBannerActions.create.includes(user.role)" @click="dialogCreateInvestor = true">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </Banner>

    <!-- Data table retail investors -->
    <v-card class="my-8">
      <DataTable
        :headers="tableHeaders"
        :items="investors"
        :totalItems="$store.state.investors.totalCountInvestors"
        @clickNextIcon="handleNextIcon"
        @clickPrevIcon="handlePrevIcon"
        @inputSearch="handleSearch"
        @changeItemsPerPage="getInvestors"
      >
        <template v-slot:[`item.id`]="{ item }">
          <router-link :to="`/inversores/retail/${item.id}`">{{ item.id }}</router-link>
        </template>
        <template v-slot:[`item.custom_automatic_investment`]="{ item }">
          <span>{{ item.automatic_investment?.active ? "Sí" : "No" }}</span>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ formatDateL(item.created_at) }}
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog create investor -->
    <DialogBox :model="dialogCreateInvestor">
      <template slot="toolbar">
        <span>Crear inversor retail</span>
      </template>
      <template slot="content">
        <v-form ref="formCreateInvestor" v-model="formCreateInvestorValid">
          <v-text-field v-model="investor.first_name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field v-model="investor.last_name" label="Apellidos" :rules="formRules.textRules" />
          <v-text-field v-model="investor.email" label="Email" :rules="formRules.emailRules" />
          <v-text-field
            v-model="investor.password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            label="Contraseña"
            :rules="formRules.passwordRequiredRules"
            @click:append="showPassword = !showPassword"
          />
          <v-checkbox
            v-model="investor.verified_email"
            :label="investor.verified_email ? 'Email verificado: Sí' : 'Email verificado: No'"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogCreateInvestor', 'formCreateInvestor')">Cerrar</v-btn>
        <v-btn text @click="create()">Crear</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import _ from "lodash";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import Banner from "@/components/elements/Banner";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";

export default {
  name: "Investors",
  components: {
    Banner,
    DataTable,
    DialogBox
  },
  mixins: [FormatDateMixin, FormRulesMixin],
  data() {
    return {
      // Banner actions
      permissionsBannerActions: {
        create: ["SUPERADMIN", "ADMIN"]
      },

      // Investors datatable
      tableHeaders: [
        { text: "Id", value: "id", sortable: false },
        { text: "Nombre", value: "first_name", sortable: false },
        { text: "Apellidos", value: "last_name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Reinversión automática", value: "custom_automatic_investment", sortable: false },
        { text: "Fecha creación", value: "created_at" }
      ],

      // Dialogs
      dialogCreateInvestor: false,
      formCreateInvestorValid: false,

      // Fields of investor
      investor: {
        first_name: "",
        last_name: "",
        email: "",
        verified_email: false,
        password: ""
      },
      showPassword: false
    };
  },
  computed: {
    ...mapState({
      investors: (state) => state.investors.investors,
      user: (state) => state.user.user
    }),
    _() {
      return _;
    }
  },
  async created() {
    console.log("getInvestors retail");
    await this.getInvestors({
      size: 10,
      filter: {
        type: "RETAIL"
      },
      sort: { created_at: "DESC" }
    });
  },
  methods: {
    ...mapActions({
      getInvestors: "investors/getInvestors"
    }),

    async handlePrevIcon(elasticData) {
      await this.getInvestors({ ...elasticData, filter: { type: "RETAIL" } });
    },

    async handleNextIcon(elasticData) {
      await this.getInvestors({ ...elasticData, filter: { type: "RETAIL" } });
    },

    async handleSearch(elasticData) {
      await this.getInvestors({ ...elasticData, filter: { type: "RETAIL" } });
    },

    // Create investor
    async create() {
      if (this.$refs.formCreateInvestor.validate()) {
        console.log(this.investor);
        await this.$store.dispatch("investors/registerRetailInvestor", this.investor);
        await this.getInvestors({ size: 10, filter: { type: "RETAIL" }, sort: { created_at: "DESC" } });
        this.closeDialog("dialogCreateInvestor", "formCreateInvestor");
      }
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.investor = this.$options.data().investor;
    }
  }
};
</script>
