<template>
  <v-data-table
    :headers="headers"
    :sort-by="auxSortBy"
    :sort-desc="sortDesc"
    :items="items"
    :loading="items.length === 0 && datatable_loading"
    :disable-pagination="disablePagination"
    :disable-sort="disableSort"
    :hide-default-footer="hideDefaultFooter"
    loading-text="Cargando datos..."
    :item-class="itemClass"
    :search="!hideDefaultFooter ? search : null"
    :footer-props="{
      'items-per-page-options': [10, 20, 30, 50, 100]
    }"
    @update:options="handleSort"
  >
    <template v-slot:top>
      <slot name="filters" />
      <v-text-field
        v-model="search"
        label="Buscar"
        class="px-4 px-sm-5 pt-4 mb-n2"
        append-icon="mdi-magnify"
        solo
        dense
        background-color="secondary"
        @input="hideDefaultFooter ? validateSearch($event) : null"
        :rules="[(v) => !v || v.length > 3 || !hideDefaultFooter || 'Mínimo 4 caracteres']"
      />
    </template>
    <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
      <slot :name="name" v-bind="item" />
    </template>
    <template v-if="totalItems" v-slot:footer>
      <v-divider />
      <div class="d-flex justify-end align-center pa-5 custom-footer">
        <span v-if="totalItems > 10" class="mr-4">Filas por página:</span>
        <v-select
          v-if="totalItems > 10"
          v-model="elasticData.size"
          :items="[10, 20, 30, 50, 100]"
          label=""
          style="max-width: 100px"
          class="mr-3"
          underlined
          hide-details
          dense
          @input="changeItemsPerPage()"
        />
        <div v-if="totalItems > elasticData.size && hideDefaultFooter">
          <span>
            <small>
              {{ elasticData.from + 1 }} -
              {{ elasticData.from + elasticData.size > totalItems ? totalItems : elasticData.from + elasticData.size }} de
              {{ totalItems }}
            </small>
          </span>
          <v-btn icon class="mx-3" :disabled="elasticData.from === 0" @click="handlePrevIcon(), (datatable_loading = true)">
            <v-icon>chevron_left</v-icon>
          </v-btn>
          <v-btn icon :disabled="elasticData.from + elasticData.size > totalItems" @click="handleNextIcon(), (datatable_loading = true)">
            <v-icon>chevron_right</v-icon>
          </v-btn>
        </div>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      datatable_loading: true,
      search: "",
      auxSortBy: this.sortBy,

      // Pagination variables
      elasticData: {
        query: "",
        size: 10,
        from: 0
      }
    };
  },
  props: {
    headers: {
      type: Array,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    sortBy: {
      type: [String, undefined],
      required: false,
      default: "created_at"
    },
    sortDesc: {
      type: Boolean,
      required: false,
      default: true
    },
    disablePagination: {
      type: Boolean,
      required: false,
      default: true
    },
    disableSort: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDefaultFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    itemClass: {
      type: Function,
      required: false
    },

    // Pagination props
    totalItems: {
      type: Number,
      required: false
    }
  },
  computed: {
    ...mapState("user", ["user", "dbUser"])
  },
  async created() {
    await this.$store.dispatch("user/getDbUserInfo");

    setTimeout(() => {
      this.datatable_loading = false;
    }, 4000);
  },
  methods: {
    changeItemsPerPage() {
      this.elasticData.from = 0;
      this.$emit("changeItemsPerPage", this.elasticData);
    },

    // Validate if the search term is long enought
    validateSearch(searchTerm) {
      if (searchTerm.length === 0) this.auxSortBy = this.sortBy;
      else if (searchTerm.length > 3) this.auxSortBy = undefined;

      this.elasticData.query = this.search;

      if (searchTerm.length === 0 || searchTerm.length > 3) {
        this.elasticData.from = 0;

        if (this.user.role === "TECHNICIAN") {
          this.elasticData.filter
            ? (this.elasticData.filter["technician.id"] = this.user.user_id)
            : (this.elasticData.filter = { "technician.id": this.user.user_id });
        } else if (this.user.role === "SUPERTECHNICIAN") {
          this.elasticData.filter
            ? (this.elasticData.filter["partner.id"] = this.dbUser.technician.partner_id)
            : (this.elasticData.filter = { "partner.id": this.dbUser.technician.partner_id });
        }

        this.$emit("inputSearch", this.elasticData);
      }
    },

    handleNextIcon() {
      this.elasticData.from += this.elasticData.size;

      if (this.user.role === "TECHNICIAN") {
        this.elasticData.filter
          ? (this.elasticData.filter["technician.id"] = this.user.user_id)
          : (this.elasticData.filter = { "technician.id": this.user.user_id });
      } else if (this.user.role === "SUPERTECHNICIAN") {
        this.elasticData.filter
          ? (this.elasticData.filter["partner.id"] = this.dbUser.technician.partner_id)
          : (this.elasticData.filter = { "partner.id": this.dbUser.technician.partner_id });
      }

      this.$emit("clickNextIcon", this.elasticData);
    },

    handlePrevIcon() {
      this.elasticData.from -= this.elasticData.size;

      if (this.user.role === "TECHNICIAN") {
        this.elasticData.filter
          ? (this.elasticData.filter["technician.id"] = this.user.user_id)
          : (this.elasticData.filter = { "technician.id": this.user.user_id });
      } else if (this.user.role === "SUPERTECHNICIAN") {
        this.elasticData.filter
          ? (this.elasticData.filter["partner.id"] = this.dbUser.technician.partner_id)
          : (this.elasticData.filter = { "partner.id": this.dbUser.technician.partner_id });
      }

      this.$emit("clickPrevIcon", this.elasticData);
    },

    handleSort(e) {
      if (e.sortBy.length > 0) this.elasticData.sort = { [e.sortBy[0]]: e.sortDesc[0] ? "DESC" : "ASC" };
      else delete this.elasticData.sort;

      if (this.user.role === "TECHNICIAN") {
        this.elasticData.filter
          ? (this.elasticData.filter["technician.id"] = this.user.user_id)
          : (this.elasticData.filter = { "technician.id": this.user.user_id });
      } else if (this.user.role === "SUPERTECHNICIAN") {
        this.elasticData.filter
          ? (this.elasticData.filter["partner.id"] = this.dbUser.technician.partner_id)
          : (this.elasticData.filter = { "partner.id": this.dbUser.technician.partner_id });
      }

      this.$emit("inputSearch", this.elasticData);
    }
  }
};
</script>

<style scoped>
::v-deep .custom-footer {
  font-size: 15px;
}
::v-deep .custom-footer .v-select {
  max-width: 60px !important;
}
::v-deep .v-text-field .v-text-field {
  max-width: 60px;
}
::v-deep .v-data-footer {
  padding: 5px;
  justify-content: right;
}
</style>
