export default {
  data() {
    return {
      formRules: {
        textRules: [(v) => !!v || "Campo obligatorio"],
        numberRules: [(v) => !!v || "Campo obligatorio", (v) => v > 0 || "Introduce un valor positivo"],
        numberRulesAllowZero: [(v) => v !== "" || "Campo obligatorio", (v) => v >= 0 || "Introduce un valor positivo"],
        passwordRequiredRules: [(v) => v.length >= 6 || "La contraseña debe tener al menos 6 caracteres"],
        passwordNotRequiredRules: [(v) => !v || v.length === 0 || v.length >= 6 || "La contraseña debe tener al menos 6 caracteres"],
        emailRules: [
          (v) => /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,10}(\.[a-z]{2,10})?$/.test(v) || "Introduce un Email válido",
          (v) => !!v || "Campo obligatorio"
        ],
        phoneRules: [(v) => !!v || "Campo obligatorio"],
        imageRequiredRules: [(v) => !!v || "Campo obligatorio", (v) => !v || v.size < 2000000 || "La imagen debe pesar menos de 2MB"],
        imageNotRequiredRules: [(v) => !v || v.size < 2000000 || "La imagen debe pesar menos de 2MB"],

        // Request
        requestNumberRules: [
          (v) => /^-?\d*\.{0,1}\d+$/.test(v) || v === "" || v === undefined || "Este campo debe ser únicamente numérico",
          (v) => v >= 0 || v === undefined || "Introduce un valor positivo"
        ],
        requestEmailRules: [
          (v) => /^\w+[\w-.]*@\w+((-\w+)|(\w*))\.[a-z]{2,10}(\.[a-z]{2,10})?$/.test(v) || v === "" || "Introduce un Email válido"
        ]
      }
    };
  }
};
