<template>
  <v-dialog v-model="model" transition="dialog-top-transition" :max-width="max_width" persistent>
    <v-card v-if="useRenderWatcher ? renderDialog : true">
      <v-toolbar :color="color" :dark="isdark">
        <slot name="toolbar" class="slot-toolbar" />
      </v-toolbar>
      <v-card-text class="pa-5 black--text">
        <slot name="content" />
      </v-card-text>
      <v-card-actions :class="actionsCenter ? 'justify-center' : 'justify-end'">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    model: {
      type: Boolean,
      required: true
    },
    formRef: {
      type: String,
      required: false
    },
    max_width: {
      type: String,
      required: false,
      default: "600"
    },
    color: {
      type: String,
      required: false,
      default: "primary"
    },
    isdark: {
      type: Boolean,
      required: false,
      default: false
    },
    actionsCenter: {
      type: Boolean,
      required: false,
      default: false
    },
    useRenderWatcher: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      renderDialog: false
    };
  },
  watch: {
    model(val) {
      setTimeout(
        () => {
          this.renderDialog = val;
        },
        !val ? 100 : 0
      );
    }
  }
};
</script>

<style scoped>
.slot-toolbar {
  align-items: center;
  display: flex;
  position: relative;
  z-index: 0;
  width: 100%;
}
</style>
